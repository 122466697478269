/**
 * Script custom for theme
 *
 * TABLE OF CONTENT
 *
 * Header: Main menu
 * Header: Sticky menu
 *
 * Blog: Grid layout
 * Blog: Masonry layout
 * Blog: Post gallery
 *
 * Features: Back to top
 * Features: Preload
 * Features: RTL
 *
 * Footer: Sticky footer
 *
 * Sidebar: Sticky sidebar
 * Contact form: Loading
 * Custom select use select2.
 * Search box
 *
 */

(function ($) {
	"use strict";

	$(document).ready(function () {
		thim_magwp.ready();
	});

	$(window).load(function () {
		thim_magwp.load();
	});

	var thim_magwp = window.$thim_magwp = {

		data: {},

		/**
		 * Call functions when document ready
		 */
		ready: function () {
			this.header_menu();
			this.back_to_top();
			this.feature_preloading();
			this.contactform7();
			this.article_infinity();
			this.article_load_comment();
			this.social_share();
			this.search_box();
			this.ajax_search();
			this.search_trending_keys();
			this.wrap_video_sticky();
			this.blog_layout_grid();
			this.disqus_comments();
			this.home_masonry();
			this.nav_prev_next();
			this.video_player();
			this.sticky_video();
			this.rtl_support();

			// Woo
			this.woo_list_switcher();
			this.woo_minicart_hover();
			this.woo_product_slider();
			this.woo_quick_view();


			//	Shortcodes
			thim_sc_feature_news.init();
			thim_sc_login_popup.init();
			thim_sc_megamenu.init();
			thim_sc_news.init();
			thim_sc_personal_news.ready();
			thim_sc_smart_news.init();
			thim_sc_top_news.init();
			//	End: Shortcodes

		},

		/**
		 * Call functions when window load.
		 */
		load: function () {
			this.sticky_sidebar();
			this.header_menu_mobile();
			this.parallax();
			this.post_gallery();
			this.blog_layout_masonry();
			this.sc_layout_masonry()
			this.custom_select();
			this.carousel();
			this.single_photoswipe();
			this.line_after_title();
			this.related_post_popup();
			this.home_masonry();

			//	Shortcodes
			thim_sc_carousel.init();
			thim_sc_feature_news.carousel();
			thim_sc_googlemap.load();
			thim_sc_top_news.slider();
			//	End: Shortcodes
		},

		// CUSTOM FUNCTION IN BELOW

		carousel: function () {
			if (jQuery().owlCarousel) {
				$('.thim-carousel-wrapper').each(function () {
					var item_visible = $(this).data('visible') ? parseInt($(this).data('visible')) : 6,
						item_desktopsmall = $(this).data('desktopsmall') ? parseInt($(this).data('desktopsmall')) : 5,
						itemsTablet = $(this).data('itemtablet') ? parseInt($(this).data('itemtablet')) : 4,
						itemsMobile = $(this).data('itemmobile') ? parseInt($(this).data('itemmobile')) : 1,
						autoPlay = $(this).data('autoplay') ? parseInt($(this).data('autoplay')) : true;

					$(this).owlCarousel({
						nav       : true,
						dots      : false,
						autoplay  : autoPlay,
						navText   : [
							"<i class=\'fa fa-caret-left \'></i>",
							"<i class=\'fa fa-caret-right \'></i>"
						],
						responsive: {
							480 : {
								items: itemsMobile
							},
							768 : {
								items: itemsTablet
							},
							1024: {
								items: item_desktopsmall
							},
							1200: {
								items: item_visible
							}
						}
					})
					;
				});
			}
		},

		header_menu_mobile: function () {
			$(document).on('click touch', '.menu-mobile-effect', function (e) {
				e.stopPropagation();
				$('.responsive').toggleClass('mobile-menu-open');
			});

			$(document).on('click touch', '.menu-mobile-effect-close, #wrapper-container', function (e) {
				$('body').removeClass('mobile-menu-open');
			});

			$('header li.menu-item-has-children >a, header li.tc-menu-layout-builder >a, header li.menu-item-has-children >span').after('<span class="icon-toggle"><i class="fa fa-caret-down"></i></span>');

			$('.mobile-menu-container .navbar-nav>li.menu-item-has-children >a,.mobile-menu-container .mega-menu>li.menu-item-has-children >a').after('<span class="icon-toggle"><i class="fa fa-caret-down"></i></span>');

			$('.mobile-menu-container .navbar-nav>li.menu-item-has-children .icon-toggle,.mobile-menu-container .mega-menu>li.menu-item-has-children .icon-toggle').on('click', function () {

				if ($(this).next('ul.sub-menu').is(':hidden')) {
					$(this).next('ul.sub-menu').slideDown(200, 'linear');
					$(this).html('<i class="fa fa-caret-up"></i>');
				}
				else {
					$(this).next('ul.sub-menu').slideUp(200, 'linear');
					$(this).html('<i class="fa fa-caret-down"></i>');
				}
			});
		},

		header_menu: function () {
			var $header = $('#masthead.sticky-header'),
				off_Top = ( $('.content-pusher').length > 0 ) ? $('.content-pusher').offset().top : 0,
				menuH = $header.outerHeight(),
				latestScroll = 0,
				target = menuH,
				$topbar = $('#thim-header-topbar'),
				$main = $('#main-content'),
				target_top = 0;

			if ($header.length) {
				$main.css({
					'padding-top': menuH,
				});

				$header.addClass('menu-show');

				if ($topbar.length) {
					target += $topbar.height();
					target_top = $topbar.height();
				}
			}

			if ($(window).width() <= 768) {
				$('html').addClass('thim');
				target = target_top;
				$(document).bind('touchmove', function (e) {
					if ($('body').hasClass('thim-active-search')) {
						e.preventDefault();
					}
				});
			}

			$(window).scroll(function () {
				var current = $(this).scrollTop(),
					$wrapper = $('body.single'),
					maxWpadmin = 0,
					maxHeight = 0;

				if ($('body').hasClass('single')) {
					target = target_top;
				}

				if (current > target) {
					//scroll down
					$header.removeClass('affix-top').addClass('affix').removeClass('menu-show');
					$header.css({
						top: off_Top,
					});
					$wrapper.each(function () {
						if (maxHeight < $(this).find('header.affix').height()) {
							maxHeight = $(this).find('header.affix').height();
						}
					});
					if (maxHeight > 0 && $(window).width() > 768) {
						$wrapper.each(function () {
							if ($("#wpadminbar").length) {
								maxWpadmin = $("#wpadminbar").height();
							}
							var mobile_top = $header.height() + maxWpadmin;
							$(this).find('.mobile-menu-container').css('top', mobile_top);

						});
					}
				} else {
					if (current <= $topbar.height()) {
						$header.removeClass('affix').addClass('affix-top').addClass('no-transition');
					} else {
						$('body').removeClass('mobile-menu-open');
					}
					$header.css({
						top: target_top,
					});
				}

				if (current > latestScroll && current > target * 2) {
					$header.addClass('menu-hidden').removeClass('menu-show').removeClass('no-transition');
					if ($('.sticky-sidebar').length && current < $('.sticky-sidebar').offset().top - menuH) {
						$('.theiaStickySidebar').css({
							'margin-top': '0px'
						});
					}
				} else {
					$header.removeClass('menu-hidden').addClass('menu-show');
					if ($header) {
						if ($('.sticky-sidebar').length && current >= $('.sticky-sidebar').offset().top) {
							$('.theiaStickySidebar').css({
								'margin-top': $("#masthead.sticky-sidebar").outerHeight() + 'px'
							});

						} else if ($('.sticky-sidebar').length && current < $('.sticky-sidebar').offset().top - menuH) {
							$('.theiaStickySidebar').css({
								'margin-top': '0px'
							});
						}
					}
				}

				latestScroll = current;
			});

			$('body:not(.mobile-menu-open) .site-header .navbar >li, body:not(.mobile-menu-open) .site-header .navbar li,.site-header .navbar li ul li').on({
				'mouseenter': function () {
					$(this).children('.sub-menu').stop(true, false).fadeIn(250);
				},
				'mouseleave': function () {
					$(this).children('.sub-menu').stop(true, false).fadeOut(250);
				}
			});

		},

		/**
		 * Back to top.
		 */
		back_to_top: function () {
			var $element = $('#back-to-top');
			$(window).scroll(function () {
				if ($(this).scrollTop() > 100) {
					$element.addClass('scrolldown').removeClass('scrollup');
				} else {
					$element.addClass('scrollup').removeClass('scrolldown');
				}
			});

			$element.on('click', function () {
				$('html,body').animate({scrollTop: '0px'}, 800);
				return false;
			});
		},


		/**
		 * Sticky sidebar
		 */
		sticky_sidebar: function (parent) {
			var parent = parent ? parent : 'body';

			var offsetTop = 30;

			if ($(window).width() < 1025) {
				return false;
			}

			if ($("#wpadminbar").length) {
				offsetTop += $("#wpadminbar").outerHeight();
			}
			if ($("#masthead.sticky-header").length) {
				offsetTop += $("#masthead.sticky-header").outerHeight();
			}

			$(parent).find(".sticky-sidebar").theiaStickySidebar({
				"containerSelector"     : "",
				"additionalMarginTop"   : offsetTop,
				"additionalMarginBottom": "0",
				"updateSidebarHeight"   : false,
				"minWidth"              : "768",
				"sidebarBehavior"       : "modern"
			});
		},

		/**
		 * Effect parallax.
		 */
		parallax: function () {
			$(window).stellar({
				horizontalOffset: 0,
				verticalOffset  : 0
			});
		},

		/**
		 * Feature: Preloading
		 */
		feature_preloading: function () {
			var $preload = $('#thim-preloading');
			if ($preload.length > 0) {
				$preload.fadeOut(1000, function () {
					$preload.remove();
				});
			}
		},


		/**
		 * Custom select use select2
		 */
		custom_select: function () {
			$('select').select2({
				minimumResultsForSearch: Infinity
			});
		},

		/**
		 * Post gallery
		 */
		post_gallery: function () {
			$('article.format-gallery .flexslider').flexslider({
				slideshow     : true,
				animation     : 'fade',
				pauseOnHover  : true,
				animationSpeed: 400,
				smoothHeight  : true,
				directionNav  : true,
				controlNav    : false,
			});
		},

		/**
		 * Custom effect and UX for contact form.
		 */
		contactform7: function () {
			$(".wpcf7-submit").on('click', function () {
				$(this).css("opacity", 0.2);
				$(this).parents('.wpcf7-form').addClass('processing');
				$('input:not([type=submit]), textarea').attr('style', '');
			});

			$(document).on('spam.wpcf7', function () {
				$(".wpcf7-submit").css("opacity", 1);
				$('.wpcf7-form').removeClass('processing');
			});


			$(document).on('invalid.wpcf7', function () {
				$(".wpcf7-submit").css("opacity", 1);
				$('.wpcf7-form').removeClass('processing');
			});

			$(document).on('mailsent.wpcf7', function () {
				$(".wpcf7-submit").css("opacity", 1);
				$('.wpcf7-form').removeClass('processing');
			});

			$(document).on('mailfailed.wpcf7', function () {
				$(".wpcf7-submit").css("opacity", 1);
				$('.wpcf7-form').removeClass('processing');
			});

			$('body').on('click', 'input:not([type=submit]).wpcf7-not-valid, textarea.wpcf7-not-valid', function () {
				$(this).removeClass('wpcf7-not-valid');
			});
		},


		/**
		 * Blog layout grid
		 */
		blog_layout_grid: function () {

			var $blog = $('.blog .grid-layout article:not(.masonry), .archive .grid-layout article:not(.masonry)'),
				max_height_content = 0;

			// Get max height content of all items.
			$blog.each(function () {
				if (max_height_content < $(this).find('.entry-content').height()) {
					max_height_content = $(this).find('.entry-content').height();
				}
			});

			// Set height content for all items.
			if (max_height_content > 0) {
				$blog.each(function () {
					$(this).find('.entry-content').css('height', max_height_content);
				});

			}

		},

		/**
		 * Blog layout masonry
		 */
		sc_layout_masonry: function () {
			$(".thim-sc-masonry-posts").isotope({
				itemSelector   : '.type-post',
				percentPosition: true,
				masonry        : {
					columnWidth: '.type-post',
					fitWidth   : true
				}
			});
		},

		/**
		 * Blog layout masonry
		 */
		blog_layout_masonry: function () {

			setTimeout(function () {
				$(".grid-layout").isotope({
					itemSelector   : '.masonry',
					percentPosition: true,
					masonry        : {
						columnWidth: '.masonry',
						fitWidth   : true
					}
				});
			}, 1000);

		},

		/**
		 * RTL
		 */
		rtl_support: function () {

			setTimeout(function () {
				$(window).trigger('resize');
			}, 150);

			$(window).resize(function () {
				var $rtl = $('body.class-rtl #wrapper-container ');
				var get_padding1 = $rtl.find('.vc_row-has-fill').css('left');
				var get_padding2 = $rtl.find('.vc_row-no-padding').css('left');

				if (get_padding1 != null) {
					var right = 0 - parseInt(get_padding1.replace('px', '')) + 15;
					$rtl.find('.vc_row-has-fill').css('right', get_padding1);
				}
				if (get_padding2 != null) {
					$rtl.find('.vc_row-no-padding').css('right', get_padding2);
				}

			});
		},

		/**
		 * Search widget
		 */
		search_box: function () {
			$('#masthead .thim-search-box').on('click', '.toggle-form', function (e) {
				e.preventDefault();
				$('body').toggleClass('thim-active-search');
				var $search = $(this).parent();
				$search.find('.search-default').slideDown();
				$search.find('.search-found').slideUp();
				setTimeout(function () {
					$search.find('.search-field').focus().val('');
				}, 400);
			});

			$(window).scroll(function () {
				if ($(window).width() > 768) {
					if ($('body').hasClass('thim-active-search')) {
						$('body').removeClass('thim-active-search');
						var $search = $('#masthead .thim-search-box');
						$search.each(function (index, form) {
							$(form).find('.search-default').slideDown();
							$(form).find('.search-found').slideUp();
						});
					}
				}
			});

			$('#main-content').on('click', function () {
				if ($('body').hasClass('thim-active-search')) {
					$('body').removeClass('thim-active-search');
					var $search = $('#masthead .thim-search-box');
					$search.each(function (index, form) {
						$(form).find('.search-default').slideDown();
						$(form).find('.search-found').slideUp();
					});
				}
			});

			//disable enter submit form
			$('#masthead .thim-search-box form').on('keyup keypress', function (e) {
				var keyCode = e.keyCode || e.which;

				if (keyCode === 13) {
					e.preventDefault();
					return false;
				} else if (keyCode === 27) {
					if ($('body').hasClass('thim-active-search')) {
						$('body').removeClass('thim-active-search');
					}
				}

			});

			//disable click submit form
			$('#masthead .thim-search-box form').on('click', 'button[type=submit]', function (e) {
				e.preventDefault();
				return false;
			});

		},

		/**
		 * Click to trending keys
		 * @author Khoapq
		 */
		search_trending_keys: function () {
			$('.thim-search-box').on('click', '.keys a', function (event) {
				event.preventDefault();

				var $searchbox = $(this).parents('.thim-search-box'),
					key = $(this).text();

				$searchbox.find('input[name=s]').val(key).trigger('keyup');

			});
		},

		/**
		 * Article Infinity
		 */
		article_infinity: function () {
			if ($('#masthead .sticky-title').length) {
				var $header = $('#masthead'),
					current_title = $('.page-content article.active .article_info').data('title'),
					sticky_title = $('#masthead .sticky-title'),
					win = $(window),
					winH = win.height(),
					load_more = true,
					old_step = 0,
					lastest_scroll = 0,
					step = 30,
					headerH = $header.height();


				var sticky_advanced = false;
				if ($('body').hasClass('sticky-advanced')) {
					sticky_advanced = true;
				}

				if (sticky_advanced) {
					$('body.single-post #masthead').append('<div class="article-percent"><span class="value"></span></div>');
					sticky_title.html(current_title);
				}

				win.scroll(function () {
					var winTop = win.scrollTop(),
						elem = $('.page-content article.active'),
						elemH = elem.innerHeight(),
						elemTop = 0;

					if (elem.length) {
						elemTop = elem.offset().top;
					}

					var elemVisible = ( winH + winTop - elemTop > 0 && winTop > elemTop - headerH) ? winTop + headerH - elemTop : 0,
						percent = Math.round(elemVisible * 100 / elemH),
						nav = elem.find('.infinity-next-post'),
						nav_url = nav.attr('data-url');

					if (sticky_advanced) {
						$('.article-percent .value').css('width', percent + '%');
					}

					//Scroll up event
					if (winTop < old_step - step) {

						if (percent < 5) {
							var prev_item = elem.prev();
							if (prev_item.length > 0) {
								var prev_url = $(prev_item).find('.article_info').data('url'),
									prev_title = $(prev_item).find('.article_info').data('title');

								elem.removeClass('active');
								prev_item.addClass('active');

								if (sticky_advanced) {
									sticky_title.html(prev_title);
								}

								window.history.pushState(null, prev_title, prev_url);
								document.title = prev_title;

								$(document).trigger('thim_article_infinity_scroll_up', [prev_item]);
							}
						}

						old_step = winTop;
					}

					//Scroll down event


					if (winTop > old_step + step) {

						thim_magwp.line_after_title();

						if (!elem.hasClass('loaded')) {
							if (winTop > elemTop && load_more === true && $('.page-content.infinity').length) {
								// Read post 1, load post 2
								if (nav_url && nav_url !== '') {
									load_more = false;
									$.ajax({
										url       : nav_url,
										dataType  : "html",
										beforeSend: function () {
											$('body').addClass('loadmore');
										},
										success   : function (data) {
											$('body').removeClass('loadmore');
											var article_html = $(data).find('article.active').removeClass('active');
											if (article_html.length > 0) {
												$('.page-content.infinity').append(article_html);
												thim_magwp.DOM_refresh();
												$(window).lazyLoadXT();
											}
											elem.addClass('loaded');
											load_more = true;
										},

										error: function () {

										}
									});
								}
							}
						} else {
							if (percent > 95) {
								var next_item = elem.next();
								if (next_item.length > 0) {
									var next_url = $(next_item).find('.article_info').data('url'),
										next_title = $(next_item).find('.article_info').data('title');

									elem.removeClass('active');
									next_item.addClass('active');

									if (sticky_advanced) {
										sticky_title.html(next_title);
									}

									window.history.pushState(null, next_title, next_url);
									document.title = next_title;

									$(document).trigger('thim_article_infinity_scroll_down', [next_item]);
								}
							}
						}

						old_step = winTop;
					}
					lastest_scroll = winTop;

				});
			}
		},

		/**
		 * Refresh DOM after append
		 */
		DOM_refresh: function () {
			this.sticky_sidebar('article:last-child');
			this.wrap_video_sticky();
			this.video_player();
			this.sticky_video();
			this.single_photoswipe();
			this.support_adrotate_group();
			this.social_count();
			if ($('.kk-star-ratings').length) {
				$('.kk-star-ratings').kkstarratings();
			}
		},


		/**
		 * Load all comment
		 */
		article_load_comment: function () {
			$(document).on('click', '#comments .load-all', function () {
				var $comments = $(this).parent();
				if ($comments.hasClass('active')) {
					$(this).text($(this).attr('data-open'));
				} else {
					$(this).text($(this).attr('data-close'));
				}

				$comments.toggleClass('active');
				$(this).prev().slideToggle('slow');
			});
		},

		/**
		 * Popup when click on share social icon.
		 */
		social_share: function () {
			this.social_count();
			$(document).on('click', '.thim-social-share.popup a', function (event) {
				event.preventDefault();
				var shareurl = $(this).attr('href');
				if ($(this).hasClass('comments')) {
					var $article = $(this).parents('article'),
						$comment = $article.find('#comments, .disqus-comment'),
						commentTop = $comment.offset().top;

					$('html, body').animate({
						scrollTop: commentTop - 100
					}, 'slow', function () {
						if (!$comment.find('.inner-comments').is(':visible')) {
							$comment.find('.load-all, #dcl_comment_btn').trigger('click');
						}
					});

				} else if ($(this).hasClass('more-icon')) {
					$(this).parents('.thim-social-share').toggleClass('open');
					setTimeout(function () {
						$('.thim-social-share').removeClass('open');
					}, 5000);
				} else {
					var top = (screen.availHeight - 500) / 2;
					var left = (screen.availWidth - 500) / 2;
					var popup = window.open(shareurl, 'social sharing', 'width=650,height=520,left=' + left + ',top=' + top + ',location=0,menubar=0,toolbar=0,status=0,scrollbars=1,resizable=1');
					return false;
				}
			});

			$(document).on('click', '.thim-list-share .share-title', function (event) {
				event.stopPropagation();
				$(this).parents('.thim-list-share').toggleClass('open');
				setTimeout(function () {
					$(this).parents('.thim-list-share').siblings().removeClass('open');
				}, 5000);

			});

			$('body').on('click', function () {
				$('.thim-list-share').removeClass('open');
			});

		},

		/**
		 * Social counter
		 */
		social_count: function () {
			// social counter
			if ($('body.single').length > 0) {
				$('.thim-social-share').each(function (index, element) {
					if (!$(element).hasClass('loaded')) {
						var shareUrl = $(element).attr('data-link');
						$.getJSON('https://count.donreach.com/?url=' + encodeURIComponent(shareUrl) + "&callback=?", function (data) {
							var count = data.total;
							if (count > 1000) {
								count = (count / 1000).toFixed(1);
								if (count > 1000) {
									count = (count / 1000).toFixed(1) + "M";
								}
								else {
									count = count + "K";
								}
							}
							$(element).addClass('loaded').find('.number').html(count);
						});
					}
				});
			}
		},

		/**
		 * Ajax search
		 * Author: Khoapq
		 */
		ajax_search: function () {
			var timer;
			var template = wp.template('thim-search-results');

			$("#masthead .thim-search-box input[name=s], .error-404 .thim-search-box input[name=s]").keyup(function () {
				var $parent = $(this).parents('.thim-search-box');
				clearTimeout(timer);
				var ms = 500;
				var s = $(this).val();

				timer = setTimeout(function () {
					if (s.length) {
						var data = {
							action: 'thim_search',
							s     : s
						};
						$.ajax({
							type      : "POST",
							url       : ajaxurl,
							data      : data,
							beforeSend: function () {
								$parent.find('.results .search-default').slideUp();
								$parent.addClass('loadmore');
								$parent.find('.results .search-found').html('').slideDown();
							},
							success   : function (response) {
								$('.search-found').html(template(response.data));
								$parent.removeClass('loadmore');
							}
						});
					}
				}, ms);
			});
		},

		/**
		 * Khoapq
		 *
		 * Home Masonry with sidebar
		 */
		home_masonry: function () {
			var $home_masonry = $(".home-masonry");

			var options = {
				percentPosition: true,
				itemSelector   : '.grid-item',
				masonry        : {
					columnWidth: '.grid-sizer',
				},
				stamp          : '#sidebar-masonry',
			};

			var $grid = $home_masonry.find('> .sc-loop').isotope(options);

			var $button = $('.icon-loading'),
				page = 2,
				max_page = parseInt($home_masonry.attr('data-max-page')),
				ads = $home_masonry.attr('data-ads'),
				ad_type = $home_masonry.attr('data-ad_type'),
				ad_id = $home_masonry.attr('data-ad_id'),
				col_class = $home_masonry.attr('data-class'),
				loading = false;

			var scrollHandling = {
				allow  : true,
				reallow: function () {
					scrollHandling.allow = true;
				},
				delay  : 400 //(milliseconds) adjust to the highest acceptable value
			};

			var timer, lastScrollTop = 0;

			$(window).scroll(function () {

				var st = $(this).scrollTop();
				if (st > lastScrollTop) {
					// downscroll code
					clearTimeout(timer);

					timer = setTimeout(function () {
						$grid.isotope().isotope('layout');
					}, 500);

					if (!loading && scrollHandling.allow && page <= max_page) {
						setTimeout(scrollHandling.reallow, scrollHandling.delay);
						var offset = $button.offset().top - $(window).height();

						if (offset < st) {

							loading = true;
							scrollHandling.allow = false;

							var data = {
								action   : 'thim_masonry_loadmore',
								page     : page,
								ads      : ads,
								ad_type  : ad_type,
								ad_id    : ad_id,
								col_class: col_class,
							};

							$.ajax({
								type      : "POST",
								url       : ajaxurl,
								data      : data,
								beforeSend: function () {
									$home_masonry.addClass('loadmore');
								},
								success   : function (res) {
									if (res.success) {
										var $items = $(res.data);
										$grid.isotope()
											.append($items)
											.isotope('appended', $items);

										page = page + 1;
										loading = false;

										$(window).lazyLoadXT();
										$thim_magwp.support_adrotate_group();

										setTimeout(function () {
											$grid.isotope().isotope('layout');
										}, 100);

									}
									$home_masonry.removeClass('loadmore');
								}
							});
						}
					}
				}
				lastScrollTop = st;

			});
		},


		/**
		 * Post single gallery popup
		 * @author khoapq
		 */
		single_photoswipe: function () {

			$('.post .the-content').each(function () {
				var $pswp = $('.pswp')[0];
				var image = [];
				var $pic = $(this),
					getItems = function () {
						var items = [];
						$pic.find('img').each(function () {

							var $img = $(this),
								src = $img.attr('src'),
								width = $img.attr('width'),
								height = $img.attr('height');

							var item = {
								src: src,
								w  : width,
								h  : height
							}

							items.push(item);

						});
						return items;
					}

				var items = getItems();

				$.each(items, function (index, value) {
					image[index] = new Image();
					image[index].src = value['src'];
				});

				$pic.on('click', 'img', function (event) {
					event.stopImmediatePropagation();
					event.preventDefault();

					var $img = $(this),
						src = $img.attr('src'),
						width = $img.attr('width'),
						height = $img.attr('height'),
						index = 0;

					var item = {
						src: src,
						w  : width,
						h  : height
					}

					$.each(items, function (i, value) {
						if (value.src == item.src) {
							index = i;
						}
					});

					var options = {
						index          : index,
						showHideOpacity: true,
					}

					var lightBox = new PhotoSwipe($pswp, PhotoSwipeUI_Default, items, options);
					lightBox.init();

					// scroll to position before fullscreen
					var wTop = $(document).scrollTop();
					lightBox.listen('destroy', function () {
						$('html,body').animate({scrollTop: wTop}, 1);
					});

				});

			});
		},

		/**
		 * Line in heading sc, widget title
		 */
		line_after_title: function () {
			var $sc_title = $('.widget-title, .sc-title'),
				$width_span = 0;

			$sc_title.each(function () {
				$width_span = $(this).find('.title').width();
				$(this).find('.line').css('width', $width_span);

			});
		},


		/**
		 * Feature: Related post popup.
		 */
		related_post_popup: function () {
			var $popup = $('#thim-related-post-popup'),
				openClass = 'popup-open',
				openPoint,
				closePoint,
				scrollTop;

			if (!$popup.length) {
				return;
			}

			// Open popup.
			var popupClose = function ($el) {
				$el.removeClass(openClass);
			};

			// Close popup.
			var popupOpen = function ($el) {
				$el.addClass(openClass);
			};

			// Hide popup button.
			$popup.find('.close').on('click', function (e) {
				e.preventDefault();

				$popup.hide();
			});

			var popupInit = function (post_elem) {
				var popup_post_elem = post_elem.find('.popup-post');

				if (!popup_post_elem.length) {
					return;
				}

				setTimeout(function () {
					$popup.find('.popup-post').remove();
					$popup.find('.popup-content').append(popup_post_elem.clone().show());
				}, 500);

				openPoint = post_elem.offset().top + 600;
				closePoint = post_elem.offset().top + post_elem.height();

				$(window).on('scroll', function () {
					scrollTop = $(window).scrollTop();

					if (scrollTop >= openPoint && scrollTop < closePoint) {
						popupOpen($popup);
					} else {
						popupClose($popup);
					}
				});
			}

			// Init for first article.
			popupInit($('#main-content > .page-content > .post:first-of-type'));

			// When scroll to other post.
			$(document).on('thim_article_infinity_scroll_down', function (e, next_post) {
				popupInit(next_post);
			});

			$(document).on('thim_article_infinity_scroll_up', function (e, prev_post) {
				popupInit(prev_post);
			});
		},

		// Wrap video link by <div>.
		wrap_video_sticky: function () {
			// Check if is single page.
			if (!$('body.single').length || $(window).width() < 1025) {
				return;
			}

			var tmpl = wp.template('thim-sticky-video');

			var players = mejs.players;

			var getYoutubeId = function (url) {
				var id = '';
				url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
				if (url[2] !== undefined) {
					id = url[2].split(/[^0-9a-z_\-]/i);
					id = id[0];
				}
				else {
					id = url;
				}
				return id;
			};

			// Remove wp default player.
			for (var x in players) {
				var player = players[x];

				if ($(player).hasClass('me-plugin')) {
					player.remove();
				}

				if ($(player.domNode).hasClass('wp-video-shortcode')) {
					player.remove();
				}

			}

			$('.page-content article').each(function () {
				if (!$(this).hasClass('loaded')) {

					var $iframe = $(this).find('iframe'),
						src = $iframe.attr('src'),
						width = $iframe.attr('width'),
						height = $iframe.attr('height');

					if ($iframe && !$iframe.hasClass('me-plugin') && !$iframe.hasClass('mejs-shim')) {
						if ((typeof src != 'undefined') && src.indexOf('https://player.vimeo.com') === 0) {
							// Vimeo.
							$iframe.replaceWith(tmpl({
								video_type_class: 'video-type-vimeo',
								video_type      : 'video/vimeo',
								video_url       : src,
								video_width     : width,
								video_height    : height
							}));
						} else if ((typeof src != 'undefined') && src.indexOf('https://www.youtube.com') === 0) {
							// Youtube.
							$iframe.replaceWith(tmpl({
								video_type_class: 'video-type-youtube',
								video_type      : 'video/youtube',
								video_url       : 'https://www.youtube.com/watch?v=' + getYoutubeId(src),
								video_width     : width,
								video_height    : height
							}));
						}
					}
				}
			});

			$('.page-content article').each(function () {
				if (!$(this).hasClass('loaded')) {
					$(this).find('.wp-video-shortcode').wrap('<div class="thim-sticky-video video-type-source-file"><div class="video-content"></div></div>');
				}
			});
		},

		// Init video player.
		video_player: function () {
			// Check if is single page.
			if (!$('body.single').length || $(window).width() < 1025) {
				return;
			}

			if (!$('.thim-sticky-video').length) {
				return;
			}

			$('.thim-sticky-video').each(function (i, value) {

				var $article = $(this).parents('article.active');

				if (!$article.hasClass('loaded')) {
					var video_elem = $(this),
						video_height,
						breakpoint,
						options;


					if (!video_elem.hasClass('player-loaded')) {
						// Use mediaelement player.
						options = $.extend(_wpmejsSettings, {
							success: function (mediaElement) {
								mediaElement.addEventListener('play', function () {
									video_elem.attr('data-playing', 1);
									video_elem.trigger('thim_video_player_play');
								}, false);

								mediaElement.addEventListener('pause', function () {
									video_elem.attr('data-playing', 0);
									video_elem.trigger('thim_video_player_pause');
								}, false);

								video_elem.addClass('player-loaded');
							}
						});

						// Init mediaelement player for youtube and vimeo.
						var player = new MediaElementPlayer(video_elem.find('video'), options);


						video_height = video_elem.height();
						breakpoint = video_elem.offset().top + 50;
						video_elem.height(video_height);

						// Show/hide popup when scrolling.
						$(window).on('scroll', function () {
							var isStickable = parseInt(video_elem.attr('data-playing')) && !video_elem.hasClass('not-sticky');

							var scrollTop = $(window).scrollTop();

							if (scrollTop > breakpoint && isStickable) {
								// Show sticky video.
								video_elem.addClass('popup-opened');
							} else {
								// Hide sticky video.
								video_elem.removeClass('popup-opened');
							}
						});

						// Click close button.
						video_elem.find('.close').on('click', function (e) {
							e.preventDefault();

							video_elem.addClass('not-sticky').removeClass('popup-opened');
						});
					}


				}


			});
		},

		// Sticky video.
		sticky_video: function () {
			// Check if is single page.
			if (!$('body.single').length || $(window).width() < 1025) {
				return;
			}

			var stickVideo = function (video_elem) {
				video_elem.addClass('popup-opened');
				$(document).trigger('thim_stick_video');
			};

			var unStickVideo = function (video_elem) {
				video_elem.removeClass('popup-opened');
				$(document).trigger('thim_unstick_video');
			};

			var stickVideoInit = function (post_elem) {

				var sticky_elems = post_elem.find('.thim-sticky-video'),
					viewHeight = $(window).height();

				$(window).on('scroll', function () {
					sticky_elems.each(function () {
						var sticky_elem = $(this),
							player = sticky_elem.find('video')[0].player,
							viewTop = $(window).scrollTop(),
							viewBottom = viewTop + viewHeight,
							aTop = post_elem.offset().top,
							aBottom = aTop + post_elem.height();

						if (sticky_elem.attr('data-playing') == '1') {

							var videoTop = sticky_elem.offset().top,
								videoHeight = sticky_elem.height(),
								videoBottom = videoTop + videoHeight;

							if (((viewTop > videoTop) && ( (viewBottom - (viewHeight / 2)) < aBottom)) || ((viewBottom < videoBottom - 50 ) && (viewBottom > aTop))) {
								stickVideo(sticky_elem);
							} else {
								unStickVideo(sticky_elem);
							}

							if (((viewBottom - (viewHeight / 2)) > aBottom) || (viewBottom < aTop)) {
								player.pause();
								player.setVolume(0);
							}

						} else {
							unStickVideo(sticky_elem);
						}

					});
				});
			};

			stickVideoInit($('#main-content > .page-content > .post:first-of-type'));

			// When scroll to other post.
			$(document).on('thim_article_infinity_scroll_down', function (e, next_post) {
				stickVideoInit(next_post);
			});

			$(document).on('thim_article_infinity_scroll_up', function (e, prev_post) {
				stickVideoInit(prev_post);
			});

			// Calculate bottom offset when show/hide related post popup.
			$(document).on('thim_stick_video', function () {
				if ($('#thim-related-post-popup').hasClass('popup-open')) {
					var relatedPopupHeight = $('#thim-related-post-popup').height();

					$('.thim-sticky-video.popup-opened .video-content').css({
						bottom: relatedPopupHeight + 10
					});
				} else {
					$('.thim-sticky-video.popup-opened .video-content').css({
						bottom: 5
					});
				}
			});
		},

		/**
		 * Disqus comment system
		 * @author khoapq
		 */
		disqus_comments: function () {
			$(document).on('click', '.disqus-comment button', function () {
				var $comments = $(this).parents('.disqus-comment');

				var identifier = $(this).attr('data-identifier'),
					url = $(this).attr('data-url'),
					title = $(this).attr('data-title'),
					disqus_shortname = $comments.attr('data-shortname'),
					lang = $comments.attr('data-lang'),
					form = '<div id="disqus_thread"></div>';

				$('#disqus_thread').remove();

				$(this).fadeOut();
				$('.comments button').show();

				$comments.find('.comment-form').append(form);

				if (typeof DISQUS == 'undefined') {
					var dsq = document.createElement('script');
					dsq.type = 'text/javascript';
					dsq.async = true;
					dsq.src = '//' + disqus_shortname + '.disqus.com/embed.js';
					(document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(dsq);
					thim_magwp.disqus_reset(identifier, url, title, lang);
				} else {
					thim_magwp.disqus_reset(identifier, url, title, lang);
				}
			});
		},

		/**
		 * Disqus reset
		 * @author khoapq
		 *
		 * @param identifier
		 * @param url
		 * @param title
		 * @param lang
		 */
		disqus_reset: function (identifier, url, title, lang) {
			DISQUS.reset({
				reload: true,
				config: function () {
					this.page.identifier = identifier;
					this.page.url = url;
					this.page.title = title;
					this.language = lang;
				}
			});
		},

		/**
		 * support plugin Adrotate in infinity scroll
		 * @author khoapq
		 */
		support_adrotate_group: function () {
			if (typeof thim_js_adrotate != 'undefined') {
				$.each(thim_js_adrotate, function (index, group) {
					var slider = '.g-' + group.id;
					$(slider).each(function () {
						if ($(this).find(' > div').attr('style')) {
							//nothing
						} else {
							if (jQuery.fn.gslider) {
								jQuery(this).gslider({
									groupid: group.id,
									speed  : group.adspeed
								});
							}
						}
					});
				});
			}
		},

		/**
		 *
		 * @param post_id
		 * @param time_start
		 */
		ajax_countviews: function (post_id, time_start) {
			time_start = time_start ? time_start : 5000;

			setTimeout(function () {
				var data = {
					action : 'thim_ajax_countviews',
					post_id: post_id
				};
				$.ajax({
					type   : "POST",
					url    : ajaxurl,
					data   : data,
					success: function (response) {
						//nothing
					}
				});
			}, time_start);

		},


		/**
		 * single video, next/prev video related
		 * @author khoapq
		 */
		nav_prev_next: function () {
			$('.nav-videos .nav').live('click', function (event) {
				event.preventDefault();

				var $sc = $(this).parents('.nav-videos');
				var $loop = $(this).parents('.related-videos-wrapper');

				var page = parseInt($(this).attr('data-page')),
					max_page = parseInt($sc.attr('data-max-page')),
					postid = parseInt($sc.attr('data-postid')),
					tag = parseInt($sc.attr('data-tag')),
					loading = false;

				if (page <= max_page && page > 0) {
					if (!loading) {

						loading = true;
						var data = {
							action: 'thim_related_videos_nav',
							page  : page,
							postid: postid,
							tag   : tag,
						};

						var current_page = 1;

						if ($(this).hasClass('nav-next')) {
							current_page = page - 1;
						} else {
							current_page = page + 1;
						}

						thim_magwp.data[postid + current_page] = $loop.html();

						if (thim_magwp.data[postid + page]) {
							$loop.hide().html(thim_magwp.data[postid + page]).fadeIn('slow');
						} else {
							$.ajax({
								type      : "POST",
								url       : ajaxurl,
								data      : data,
								beforeSend: function () {
									$loop.addClass('loading');
								},
								success   : function (res) {
									if (res.success) {
										$loop.hide().html(res.data).fadeIn('slow');
										page = page + 1;
										loading = false;
									}
									$loop.removeClass('loading');
									$(window).lazyLoadXT();
								}
							});
						}
					}
				}
			});
		},


		/**
		 * Product Grid, List switch
		 */
		woo_list_switcher: function () {

			var activeClass = 'switcher-active';
			var gridClass = 'product-grid';
			var listClass = 'product-list';
			$('.switchToList').on('click', function () {
				switchToList();
			});
			$('.switchToGrid').on('click', function () {
				switchToGrid();
			});

			function switchToList() {
				$('.switchToList').addClass(activeClass);
				$('.switchToGrid').removeClass(activeClass);
				$('.archive_switch').fadeOut(300, function () {
					$(this).removeClass(gridClass).addClass(listClass).fadeIn(300);
				});
				localStorage.magwp_products_page = 'list';
			}

			function switchToGrid() {
				$('.switchToGrid').addClass(activeClass);
				$('.switchToList').removeClass(activeClass);
				$('.archive_switch').fadeOut(300, function () {
					$(this).removeClass(listClass).addClass(gridClass).fadeIn(300);
				});
				localStorage.magwp_products_page = 'grid';
			}


			if (typeof(Storage) !== "undefined") {
				if (typeof localStorage.magwp_products_page != 'undefined') {
					if (localStorage.magwp_products_page === 'list') {
						$('.archive_switch').removeClass('product-grid').addClass('product-list');
						$('.switchToList').addClass(activeClass);
					} else {
						$('.switchToGrid').addClass(activeClass);
						$('.archive_switch').removeClass('product-list').addClass('product-grid');
					}
				} else {
					$('.switchToGrid').addClass(activeClass);
					$('.archive_switch').removeClass('product-list').addClass('product-grid');
				}
			} else {
				$('.switchToGrid').addClass(activeClass);
				$('.archive_switch').removeClass('product-list').addClass('product-grid');
			}

		},


		/**
		 * Effect when hover on mini cart.
		 */
		woo_minicart_hover: function () {
			$("body").on("mouseenter mouseleave", ".widget_shopping_cart", function (e) {
				var cart = $('.widget_shopping_cart');
				var cart_content = cart.find('.widget_shopping_cart_content');
				if (e.type == "mouseenter") {
					cart_content.stop().slideDown(200);
				} else {
					cart_content.stop().slideUp(200);
				}
			});
		},

		/**
		 * Product single slider
		 */
		woo_product_slider: function () {
			$('#carousel').flexslider({
				animation    : "slide",
				direction    : "vertical",
				controlNav   : false,
				animationLoop: false,
				slideshow    : false,
				itemWidth    : 101,
				itemMargin   : 5,
				maxItems     : 3,
				directionNav : false,
				asNavFor     : '#slider'
			});

			$('#slider').flexslider({
				animation    : "slide",
				controlNav   : false,
				animationLoop: false,
				directionNav : false,
				slideshow    : false,
				sync         : "#carousel"
			});
		},


		/**
		 * Quickview product
		 */
		woo_quick_view: function () {
			$('.quick-view').on('click', function (e) {
				/* add loader  */
				$('.quick-view span').css('display', 'none');
				$(this).append('<span class="loading dark"></span>');
				var product_id = $(this).attr('data-prod');
				var data = {action: 'jck_quickview', product: product_id};
				$.post(ajaxurl, data, function (response) {
					$.magnificPopup.open({
						mainClass: 'my-mfp-zoom-in',
						items    : {
							src : response,
							type: 'inline'
						}
					});
					$('.quick-view span').css('display', 'inline-block');
					$('.loading').remove();
					$('.product-card .wrapper').removeClass('animate');
					setTimeout(function () {
						$('.product-lightbox form').wc_variation_form();
					}, 600);
					thim_magwp.woo_product_slider();
				});
				e.preventDefault();
			});
		},

	};

})(jQuery);
