(function ($) {
	"use strict";

	var thim_sc_top_news = window.thim_sc_top_news = {

		init: function () {
			this.load_more();
		},

		load_more: function () {
			var $shortcodes = $('.thim-sc-top-news');

			$shortcodes.each(function () {
				var $sc = $(this);
				var button = $sc.find('.load-more'),
					page = 2,
					max_page = parseInt($sc.attr('data-max-page')),
					params = $sc.attr('data-params'),
					loading = false;

				$sc.on('click', '.load-more', function (event) {
					event.preventDefault();
					$(this).remove();

					if (page <= 2 && page <= max_page) {
						if (!loading) {
							loading = true;
							var data = {
								action: 'thim_top_news_loadmore',
								page  : page,
								params: params,
							};

							$.ajax({
								type      : "POST",
								url       : ajaxurl,
								data      : data,
								beforeSend: function () {
									$sc.addClass('loadmore');
								},
								success   : function (res) {
									if (res.success) {
										$sc.find('.sc-wrapper').append(res.data);
										page = page + 1;
										loading = false;
										if ($('.home-masonry').length > 0) {

											$('.home-masonry > .sc-loop').isotope('layout');
										}
									}
									$(window).lazyLoadXT();
									$sc.removeClass('loadmore');
								}
							});
						}
					}
				});
			});
		},

		slider: function () {
			$(".thim-sc-top-slider").each(function () {
				var $this = jQuery(this);
				var owl = $this.find('.owl-carousel');

				owl.owlCarousel({
					loop      : false,
					pagination: false,
					nav       : true,
					dots      : false,
					singleItem: true,
					navText   : [
						"<i class=\'fa fa-angle-left \'></i>",
						"<i class=\'fa fa-angle-right \'></i>"
					],
					responsive: {
						// breakpoint from 0 up
						0: {
							items: 1
						}
					}

				});
			});
		}

	};

})(jQuery);