(function ($) {
	"use strict";

	var thim_sc_feature_news = window.thim_sc_feature_news = {

		init: function () {
			this.slider();
			this.max_height_style4();
		},

		slider: function () {
			var $sc_news = $('.thim-sc-featured-news.slider');

			$sc_news.find('.flexslider').flexslider({
				slideshow     : true,
				animation     : 'slide',
				pauseOnHover  : true,
				animationSpeed: 400,
				smoothHeight  : true,
				directionNav  : true,
				controlNav    : false,
			});
		},

		carousel: function () {
			var $sc = $('.thim-sc-featured-news .owl-carousel'),
				items = $sc.attr('data-items'),
				autoplay = $sc.attr('data-autoplay') ? $sc.attr('data-autoplay') : false;

			$sc.owlCarousel({
				loop      : false,
				items     : items,
				nav       : true,
				dots      : false,
				mouseDrag : false,
				autoPlay  : autoplay,
				navText   : [
					"<i class=\'fa fa-caret-left\'></i>",
					"<i class=\'fa fa-caret-right\'></i>"
				],
				responsive: {
					// breakpoint from 0 up
					0   : {
						items: 1
					},
					// breakpoint from 480 up
					480 : {
						items: 2
					},
					// breakpoint from 768 up
					768 : {
						items: 3
					},
					// breakpoint from 768 up
					1024: {
						items: items
					}
				}
			});

		},

		max_height_style4: function () {

			if ($(window).width() < 768) {
				return;
			}

			var $feature_style4 = $('.thim-sc-featured-news.style4'),
				max_height = 0;

			$feature_style4.each(function () {
				if (max_height < $(this).find('.item:first-child').height()) {
					max_height = $(this).find('.item:first-child').height();
				}
			});

			if (max_height > 0) {
				var css_max_height = max_height / 2;
				$feature_style4.each(function () {
					$(this).find('.item:nth-child(2), .item:nth-child(3)').css('height', css_max_height);
				});
			}

		}

	};

})(jQuery);