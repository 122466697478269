/**
 * (c) Greg Priday, freely distributable under the terms of the GPL 2.0 license.
 */
(function ($) {
	"use strict";

	var thim_sc_carousel = window.thim_sc_carousel = {

		init: function () {

			if (jQuery().owlCarousel) {

				$('.thim-sc-carousel').each(function () {
					var item_visible = $(this).data('items') ? parseInt($(this).data('items')) : 5,
						autoplay = $(this).data('autoplay') ? parseInt($(this).data('autoplay')) : false,
						speed = $(this).data('speed') ? parseInt($(this).data('speed')) : 5000;

					$(this).owlCarousel({
						loop           : false,
						nav            : true,
						dots           : false,
						autoplay       : autoplay,
						autoPlaySpeed  : speed,
						margin         : 10,
						navText        : [
							"<i class=\'fa fa-caret-left\'></i>",
							"<i class=\'fa fa-caret-right\'></i>"
						],
						responsiveClass: true,
						responsive     : {
							0   : {
								items: 1
							},
							480 : {
								items: 1
							},
							568 : {
								items: 2
							},
							1024: {
								items: 3
							},
							1200: {
								items: item_visible
							}
						}
					});
				});
			}
		}

	};

})(jQuery);