(function ($) {
	"use strict";
	
	var thim_sc_login_popup = window.thim_sc_login_popup = {

		init: function () {
			this.login_popup();
			this.login_error();
		},

		/**
		 * Login popup form
		 */
		login_popup: function () {

			$('.thim-login').on('click', '.display-box', function (e) {
				e.preventDefault();

				var classbox = $(this).attr('data-display');

				$('.thim-login' + classbox).addClass('active').siblings().removeClass('active');
			});

			$('.thim-link-login .login, .thim-link-login .story.not-login').magnificPopup({
				type        : 'inline',
				removalDelay: 500, //delay removal by X to allow out-animation
				callbacks   : {
					beforeOpen: function () {
						this.st.mainClass = this.st.el.attr('data-effect');
					}
				},
				focus       : '#thim_login',
				midClick    : true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
			});


			$('#thim-popup-login #loginform').submit(function (event) {
				var elem = $('#thim-popup-login'),
					input_username = elem.find('#thim_login').val(),
					input_password = elem.find('#thim_pass').val();

				if (input_username == '' || input_password == '') {
					return;
				}

				elem.addClass('loading');
				elem.find('.message').slideDown().remove();

				var data = {
					action  : 'thim_login_ajax',
					username: input_username,
					password: input_password,
					remember: elem.find('#rememberme').val()
				};

				$.post(ajaxurl, data, function (response) {
					try {
						var response = JSON.parse(response);
						elem.find('.thim-login .inner-login').append(response.message);
						if (response.code == '1') {
							location.reload();
						}
						elem.removeClass('loading');
					} catch (e) {
						return false;
					}
				});

				event.preventDefault();
				return false;
			});

			$(function ($) {
				$('#thim_login, #thim_login_ac').attr('placeholder', thim_js_translate.login);
				$('#thim_pass, #thim_pass_ac').attr('placeholder', thim_js_translate.password);
			});

			$("#thim-popup-login  #loginform .login-password").each(function () {
				$(this).append("<span id=\"show_pass\"><i class=\"fa fa-eye-slash\"></i></span>");
			});

			var el = document.getElementById('show_pass');

			if (el) {
				el.addEventListener("click", function (e) {
					var pwd = document.getElementById("thim_pass");

					if (pwd.getAttribute("type") == "password") {
						pwd.setAttribute("type", "text");
					} else {
						pwd.setAttribute("type", "password");
					}
				});
			}

			$(".my-account #loginform .login-password").each(function () {
				$(this).append("<span id=\"show_pass_ac\"><i class=\"fa fa-eye-slash\"></i></span>");
			});

			var el2 = document.getElementById('show_pass_ac');

			if (el2) {
				el2.addEventListener("click", function () {
					var pwd2 = document.getElementById("thim_pass_ac");

					if (pwd2.getAttribute("type") == "password") {
						pwd2.setAttribute("type", "text");
					}
					else {
						pwd2.setAttribute("type", "password");
					}
				});
			}

		},
		/**
		 * Notifications error for form
		 */
		login_error: function () {

			// Validate login submit
			$('.thim-login form#loginform').submit(function (event) {
				var elem = $(this),
					input_username = elem.find('#thim_login, #thim_login_ac'),
					input_pass = elem.find('#thim_pass, #thim_pass_ac');

				if (input_username.length > 0 && input_username.val() == '') {
					input_username.addClass('invalid');
					event.preventDefault();
				}

				if (input_pass.length > 0 && input_pass.val() == '') {
					input_pass.addClass('invalid');
					event.preventDefault();
				}
			});

			//Register form untispam
			$('.thim-login form#registerform').submit(function (event) {
				var elem = $(this),
					input_username = elem.find('#user_login_register'),
					input_email = elem.find('#user_email'),
					input_pass = elem.find('#password'),
					input_rppass = elem.find('#repeat_password');

				var email_valid = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/igm;

				if (input_username.length > 0 && input_username.val() == '') {
					input_username.addClass('invalid');
					event.preventDefault();
				}

				if (input_email.length > 0 && ( input_email.val() == '' || !email_valid.test(input_email.val()) )) {
					input_email.addClass('invalid');
					event.preventDefault();
				}

				if (input_pass.val() !== input_rppass.val() || input_pass.val() == '') {
					input_pass.addClass('invalid');
					input_rppass.addClass('invalid');
					event.preventDefault();
				}
			});

			// Validate lostpassword submit
			$('.thim-login form#lostpasswordform').submit(function (event) {
				var elem = $(this),
					input_username = elem.find('#user_login_lostpass');

				if (input_username.length > 0 && input_username.val() == '') {
					input_username.addClass('invalid');
					event.preventDefault();
				}
			});

			// Remove class invalid
			$('.thim-login #thim_login, .thim-login #thim_pass, .thim-login #user_login_lostpass, .thim-login #user_login_register, .thim-login #thim_login_ac, .thim-login #thim_pass_ac').on('focus', function () {
				$(this).removeClass('invalid');
			});

		},

	};

})(jQuery);