(function ($) {
	"use strict";

	var thim_sc_news = window.thim_sc_news = {

		data: {},
		init: function () {
			this.nav_prev_next();
			this.nav_filter();
			this.nav_pagination();
			this.infinity_scroll();
			this.filter_orderby();
			this.nav_filter_tabs();
		},

		/**
		 * <> navigation
		 *
		 * page navigation with ajax loader
		 */
		nav_prev_next: function () {
			var $sc = $('.thim-sc-news');


			$sc.on('click', '.page-navigation .nav', function (event) {
				event.preventDefault();

				$sc = $(this).parents('.thim-sc-news');

				var page = parseInt($(this).attr('data-page')),
					max_page = parseInt($sc.attr('data-max-page')),
					params = $sc.attr('data-params'),
					loading = false,
					sc_id = $sc.attr('id');

				if (page <= max_page && page > 0) {
					if (!loading) {

						loading = true;
						var data = {
							action: 'thim_sc_news',
							page  : page,
							params: params,
						};

						var current_page = 1;

						if ($(this).hasClass('nav-next')) {
							current_page = page - 1;
						} else {
							current_page = page + 1;
						}

						thim_sc_news.data[sc_id + current_page] = $sc.find('.loop-wrapper').html();

						$sc.find('.sc-loop').removeClass('fadeIn');

						if (thim_sc_news.data[sc_id + page]) {
							setTimeout(function () {
								$sc.find('.loop-wrapper').html(thim_sc_news.data[sc_id + page]);
								$sc.find('.sc-loop').addClass('fadeIn');
							}, 300);

						} else {
							$.ajax({
								type      : "POST",
								url       : ajaxurl,
								data      : data,
								beforeSend: function () {
									$sc.addClass('loading');
								},
								success   : function (res) {
									if (res.success) {
										$sc.find('.loop-wrapper').html(res.data);
										$sc.find('.sc-loop').addClass('fadeIn');
										page = page + 1;
										loading = false;
									}
									$sc.removeClass('loading');
									$(window).lazyLoadXT();

								}
							});
						}

					}
				}
			});
		},

		/**
		 * Filter by category
		 */
		nav_filter: function () {
			var $sc = $('.thim-sc-news:not(.layout-tabs)');

			$sc.on('click', '.nav-filter a', function () {

				$sc = $(this).parents('.thim-sc-news:not(.layout-tabs)');

				var current_cat = $sc.find('.nav-filter .cat-item.current a').attr('data-cat'),
					current_down = $sc.find('.cat-dropdown');

				$sc.find('.nav-filter .cat-item').removeClass('current');

				$(this).parents('li').addClass('current');

				if (current_down.find('.pulldown-list .cat-item').hasClass('current')) {
					current_down.find('.cat-more').addClass('current');
				} else {
					current_down.find('.cat-more').removeClass('current');
				}

				var params = $sc.attr('data-params'),
					category = $(this).attr('data-cat'),
					sc_id = $sc.attr('id');

				var data = {
					action  : 'thim_sc_news',
					category: category,
					params  : params
				};

				thim_sc_news.data[sc_id + current_cat] = $sc.find('.loop-wrapper').html();

				$sc.find('.sc-loop').removeClass('fadeIn');

				if (thim_sc_news.data[sc_id + category]) {
					setTimeout(function () {
						$sc.find('.loop-wrapper').html(thim_sc_news.data[sc_id + category]);
						$sc.find('.sc-loop').addClass('fadeIn');
					}, 300);
				} else {
					$.ajax({
						type      : "POST",
						url       : ajaxurl,
						data      : data,
						beforeSend: function () {
							$sc.addClass('loading');
						},

						success: function (res) {
							if (res.success) {
								$sc.find('.loop-wrapper').html(res.data);
								$sc.find('.sc-loop').addClass('fadeIn');
							}
							$sc.removeClass('loading');
							$(window).lazyLoadXT();

							$(document).on('click', '.thim-list-share', function () {
								$(this).find('.social-links').slideToggle(300);
							});

							$(document).on('click', '.thim-list-share', function (e) {
								e.stopPropagation();
								$(this).find('.share-title').toggleClass('open');
							});

						}
					});
				}

			});

			var filter_value = thim_sc_news.nav_filter_value();
			$(window).resize(function () {
				thim_sc_news.nav_filter_resize(filter_value);
			});

		},

		/**
		 * Filter by category for tabs layout
		 */
		nav_filter_tabs: function () {
			var $sc = $('.thim-sc-news.layout-tabs');

			$sc.on('hover', '.nav-filter a', function () {

				$sc = $(this).parents('.thim-sc-news.layout-tabs');

				$sc.find('.sc-loop').hide();

				var current_cat = $sc.find('.nav-filter .cat-item.current a').attr('data-cat'),
					current_down = $sc.find('.cat-dropdown');

				$sc.find('.nav-filter .cat-item').removeClass('current');

				$(this).parents('li').addClass('current');

				if (current_down.find('.pulldown-list .cat-item').hasClass('current')) {
					current_down.find('.cat-more').addClass('current');
				} else {
					current_down.find('.cat-more').removeClass('current');
				}

				var params = $sc.attr('data-params'),
					category = $(this).attr('data-cat'),
					sc_id = $sc.attr('id');

				var data = {
					action  : 'thim_sc_news',
					category: category,
					params  : params
				};

				thim_sc_news.data[sc_id + current_cat] = $sc.find('.sc-loop').html();

				if (thim_sc_news.data[sc_id + category]) {
					$sc.find('.sc-loop').hide().html(thim_sc_news.data[sc_id + category]).fadeIn('fast');
				} else {
					$.ajax({
						type      : "POST",
						url       : ajaxurl,
						data      : data,
						beforeSend: function () {
							$sc.addClass('loading');
						},

						success: function (res) {
							if (res.success) {
								$sc.find('.sc-loop').hide().html(res.data).fadeIn('fast');
							}

							$sc.removeClass('loading');

							$(".thim-list-share").on('click', function () {
								$(this).find('.social-links').slideToggle(300);
							});

							$(".thim-list-share").on('click', function (e) {
								e.stopPropagation();
								$(this).toggleClass('open');
							});
						}
					});
				}

			});

			var filter_value = thim_sc_news.nav_filter_value();

			$(window).resize(function () {
				thim_sc_news.nav_filter_resize(filter_value);
			});

		},

		/**
		 * Responsive filter
		 */
		nav_filter_value: function () {
			var $sc = $('.thim-sc-news');

			var $filter = $sc.find('.nav-filter');
			var filter_value = [];
			$filter.each(function (index, element) {
				var list = $(this).find('.cat-list').html();
				var pulldown = $(this).find('.pulldown-list').html();
				var filter = {
					'list'    : list,
					'pulldown': pulldown
				}
				filter_value.push(filter);
			});

			return filter_value;
		},

		/**
		 * Resize windown
		 * @param filter_value
		 */
		nav_filter_resize: function (filter_value) {
			var $sc = $('.thim-sc-news');
			var windowW = $(window).width();

			var $filter = $sc.find('.nav-filter');

			$filter.each(function (index, element) {
				if (windowW <= 768) {
					$(this).find('.pulldown-list').html(filter_value[index].list + filter_value[index].pulldown);
					$(this).find('.cat-list').empty();
				} else {
					$(this).find('.pulldown-list').html(filter_value[index].pulldown);
					$(this).find('.cat-list').html(filter_value[index].list);
				}
			});
		},


		/**
		 * Ajax get post by pagination
		 */
		nav_pagination: function () {
			var $sc = $('.thim-sc-news');

			$sc.on('click', '.page-numbers', function (event) {
				event.preventDefault();

				$sc = $(this).parents('.thim-sc-news');

				var page = 2,
					max_page = parseInt($sc.attr('data-max-page')),
					sc_id = $sc.attr('id'),
					params = $sc.attr('data-params');

				if ($(this).is('a')) {
					var url = $(this).attr('href');
					page = url.match(/\d+/);
					page = page[0];
				} else {
					return;
				}

				if (page <= max_page) {
					var data = {
						action: 'thim_sc_news',
						page  : page,
						params: params,
					};

					var current_page = $sc.find('.page-numbers.current').text();
					thim_sc_news.data[sc_id + current_page] = $sc.find('.loop-wrapper').html();


					$sc.find('.sc-loop').removeClass('fadeIn');

					if (thim_sc_news.data[sc_id + page]) {

						setTimeout(function () {
							$sc.find('.loop-wrapper').html(thim_sc_news.data[sc_id + page]);
							$sc.find('.sc-loop').addClass('fadeIn');
						}, 300);

						$('html,body').animate({
								scrollTop: $sc.offset().top - $('#masthead.sticky-header').outerHeight() - 50,
							},
							800
						);
					} else {
						$.ajax({
							type      : "POST",
							url       : ajaxurl,
							data      : data,
							beforeSend: function () {
								$sc.addClass('loading');
							},
							success   : function (res) {
								if (res.success) {
									$sc.find('.loop-wrapper').html(res.data);
									$sc.find('.sc-loop').addClass('fadeIn');
									$('html,body').animate({
											scrollTop: $sc.offset().top - $('#masthead.sticky-header').outerHeight() - 50,
										},
										800
									);
								}

								$sc.removeClass('loading');
								$(window).lazyLoadXT();

								$(".thim-sc-news .share-more").on('click', function (e) {
									e.stopPropagation();
									$(this).find('.more-icon').toggleClass('open');
									$(this).toggleClass('open');
									$(this).parents('.thim-social-share').toggleClass('open');
								});

								$(".thim-list-share").on('click', function (e) {
									e.stopPropagation();
									$(this).toggleClass('open');
								});
							}
						});
					}

				}
			});
		},

		/**
		 * Infinity Scroll
		 */
		infinity_scroll: function () {
			var $button = $('.thim-sc-news .infinity-scroll');

			if ($button.length) {
				$button.each(function () {
					$button = $(this);
					var $sc = $button.parents('.thim-sc-news');

					var page = 2,
						max_page = parseInt($sc.attr('data-max-page')),
						params = $sc.attr('data-params'),
						loading = false;

					var scrollHandling = {
						allow  : true,
						reallow: function () {
							scrollHandling.allow = true;
						},
						delay  : 400 //(milliseconds) adjust to the highest acceptable value
					};


					$(window).scroll(function () {
						if (page <= max_page) {
							if (!loading && scrollHandling.allow) {
								scrollHandling.allow = false;
								setTimeout(scrollHandling.reallow, scrollHandling.delay);
								var offset = $button.offset().top - $(window).scrollTop();
								if (offset < ($(window).height() * 1.5)) {
									loading = true;
									var data = {
										action: 'thim_sc_news',
										page  : page,
										params: params,
									};

									$.ajax({
										type      : "POST",
										url       : ajaxurl,
										data      : data,
										beforeSend: function () {
											$sc.addClass('loadmore');
										},
										success   : function (res) {
											if (res.success) {
												$sc.find('> .loop-wrapper').append(res.data);
												page = page + 1;
												loading = false;
											}
											$sc.removeClass('loadmore');
											$(window).lazyLoadXT();
											$thim_magwp.support_adrotate_group();
										}
									});
								}
							}
						}
					});


				});
			}

		},

		/**
		 * Filter & Search box
		 * Orderby
		 * @author Khoapq
		 */
		filter_orderby: function () {
			var $el = $('.thim-filter-orderby');

			$el.find('select').on('change', function () {
				var $sc = $(this).parents('.thim-sc-news'),
					orderby = $(this).val(),
					params = $sc.attr('data-params'),
					page = parseInt($sc.find('.page-numbers.current').text());

				var data = {
					action : 'thim_sc_news',
					page   : page,
					params : params,
					orderby: orderby,
				};

				$.ajax({
					type      : "POST",
					url       : ajaxurl,
					data      : data,
					beforeSend: function () {
						$sc.addClass('loading');
					},
					success   : function (res) {
						if (res.success) {
							$sc.find('.loop-wrapper').hide().html(res.data).fadeIn('slow');
						}
						$sc.removeClass('loading');
						$(window).lazyLoadXT();
					}
				});
			});
		},
	};

})(jQuery);
