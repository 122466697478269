(function ($) {
	"use strict";

	var thim_sc_smart_news = window.thim_sc_smart_news = {

		data: {},

		/**
		 * Ajax load news
		 */
		init: function () {

			this.loadmore();
			this.nav();
		},

		loadmore: function () {
			$('.thim-sc-smart-news').on('click', '.load-more', function (event) {
				event.preventDefault();

				var $sc = $(this).parents('.thim-sc-smart-news');

				var $nav = $sc.find('.page-navigation'),
					params = $sc.attr('data-params'),
					loading = false;

				if (!loading) {
					var $button = $(this);
					var rank = $sc.find('.sc-loop .item').length;
					loading = true;
					var data = {
						action: 'thim_smart_news_load',
						init  : false,
						params: params,
						rank  : rank,
					};
					$.ajax({
						type      : "POST",
						url       : ajaxurl,
						data      : data,
						beforeSend: function () {
							$sc.addClass('loadmore');
							$nav.remove();
							$button.remove();
						},
						success   : function (res) {
							$sc.find('.sc-loop').append(res.data.html);
							$sc.removeClass('loadmore');
							$(window).lazyLoadXT();
						}
					});
				}
			});
		},

		nav: function () {
			var $sc = $('.thim-sc-smart-news');


			$sc.on('click', '.page-navigation .nav', function (event) {
				event.preventDefault();

				$sc = $(this).parents('.thim-sc-smart-news');

				var page = parseInt($(this).attr('data-page')),
					max_page = $sc.attr('data-max-page'),
					params = $sc.attr('data-params'),
					loading = false,
					sc_id = $sc.attr('id');

				if (page <= max_page && page > 0) {
					if (!loading) {
						var rank = $sc.find('.sc-loop .item').length;
						loading = true;

						var current_page = 1;

						if ($(this).hasClass('nav-next')) {
							current_page = page - 1;
						} else {
							current_page = page + 1;
						}

						rank = current_page * rank;

						var data = {
							action: 'thim_smart_news_load',
							page  : page,
							params: params,
							rank  : rank,
						};

						thim_sc_smart_news.data[sc_id + current_page] = $sc.find('.sc-loop').html();

						if (thim_sc_smart_news.data[sc_id + page]) {
							$sc.find('.sc-loop').hide().html(thim_sc_smart_news.data[sc_id + page]).fadeIn('slow');
						} else {
							$.ajax({
								type      : "POST",
								url       : ajaxurl,
								data      : data,
								beforeSend: function () {
									$sc.addClass('loading');
								},
								success   : function (res) {
									if (res.success) {
										$sc.find('.sc-loop').hide().html(res.data.html).fadeIn('slow');
										page = page + 1;
										loading = false;
									}
									$sc.removeClass('loading');
									$(window).lazyLoadXT();

								}
							});
						}

					}
				}
			});
		},


	};

})(jQuery);