(function ($) {
	"use strict";

	var thim_sc_megamenu = window.thim_sc_megamenu = {

		data: {},
		init: function () {
			this.tabs();
			this.nav_prev_next();
			this.lazyload();
		},


		tabs: function () {
			var $sc = $('.thim-sc-megamenu');

			$sc.on('hover', '.nav-filter .cat-item a', function (event) {
				event.preventDefault();
				$sc = $(this).parents('.thim-sc-megamenu');

				var cat_slug = $(this).attr('data-cat');

				$sc.find('.nav-filter .cat-item').removeClass('current');

				$(this).parents('.cat-item').addClass('current');

				$sc.find('.tab.' + cat_slug).show().addClass('current').siblings().hide().removeClass('current');

			});
		},

		/**
		 * <> navigation
		 *
		 * page navigation with ajax loader
		 */
		nav_prev_next: function () {
			var $sc = $('.thim-sc-megamenu');


			$sc.on('click', '.page-navigation .nav', function (event) {
				event.preventDefault();

				$sc = $(this).parents('.thim-sc-megamenu');

				var page = parseInt($(this).attr('data-page')),
					max_page = parseInt($sc.attr('data-max-page')),
					params = $sc.attr('data-params'),
					loading = false,
					sc_id = $sc.attr('id');

				if (page <= max_page && page > 0) {
					if (!loading) {

						loading = true;
						var data = {
							action: 'thim_sc_megamenu',
							page  : page,
							params: params,
						};

						var current_page = 1;

						if ($(this).hasClass('nav-next')) {
							current_page = page - 1;
						} else {
							current_page = page + 1;
						}


						if ($sc.hasClass('layout-tabs')) {
							data['category'] = $sc.find('.tab.current').attr('data-cat');
							sc_id = sc_id + data['category'];
						}

						thim_sc_megamenu.data[sc_id + current_page] = $sc.find('.sc-loop').html();

						if (thim_sc_megamenu.data[sc_id + page]) {
							$sc.find('.sc-loop').hide().html(thim_sc_megamenu.data[sc_id + page]).fadeIn('slow');
						} else {
							$.ajax({
								type      : "POST",
								url       : ajaxurl,
								data      : data,
								beforeSend: function () {
									$sc.addClass('loading');
								},
								success   : function (res) {
									if (res.success) {
										if ($sc.hasClass('layout-tabs')) {
											$sc.find('.tab.current').hide().html(res.data).fadeIn('slow');
										} else {
											$sc.find('.sc-loop').hide().html(res.data).fadeIn('slow');
										}
										page = page + 1;
										loading = false;
									}
									$sc.removeClass('loading');
									$(window).lazyLoadXT();

								}
							});
						}

					}
				}
			});
		},


		lazyload: function () {
			$(document).on('mouseenter', '.menu-item, .thim-sc-megamenu .nav-filter .cat-item a', function () {
				$(window).lazyLoadXT();
			});
		},


	};

})(jQuery);
